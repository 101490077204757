import { cryptoCurrencies, CryptoCurrency, CryptoDictionary } from "../app/types";
import WalletAddressValidator from "multicoin-address-validator";

interface Address {
    isValid: (address: string) => CryptoDictionary<boolean>,
    isValidAny: (address: string | CryptoDictionary<boolean>) => boolean,
    type: (address: string) => CryptoCurrency | null
}

function CurrencyFor(c: CryptoCurrency): string {
    switch (c) {
        case "tBTC":
            return "BTC";
        case "tLTC":
            return "LTC";
        default:
            return c;
    }
}

function NetworkFor(c: CryptoCurrency): string {
    switch (c) {
        case "tBTC":
        case "tLTC":
            return "testnet";
        default:
            return "prod";
    }
}

const Address = {
    isValid(address: string) : CryptoDictionary<boolean> {
        const result = {} as CryptoDictionary<boolean>;
        for (const c of cryptoCurrencies) {
            const val : string = CurrencyFor(c);
            const networkType : string = NetworkFor(c);
            result[c] = WalletAddressValidator.validate(address, val, networkType);
        }
        return result;
    },
    isValidAny(address: string | CryptoDictionary<boolean>): boolean {
        for (const c of cryptoCurrencies) {
            const val : string = CurrencyFor(c);
            const networkType : string = NetworkFor(c);
            if ((typeof address === "string" && WalletAddressValidator.validate(address, val, networkType))
                || (typeof address !== "string" && address[c]))
                return true;
        }

        return false;
    },
    type(address: string): CryptoCurrency | null {
        for(const c of cryptoCurrencies) {
            const val : string = CurrencyFor(c);
            const networkType : string = NetworkFor(c);
            if (WalletAddressValidator.validate(address, val, networkType))
                return c;
        }

        return null;
    }
} as Address;

export default Address;

interface Numbers {
    Equal(n: number | undefined, o: number): boolean,
    GreaterThan(n: number | undefined, o: number): boolean,
    GreaterThanEqual(n: number | undefined, o: number): boolean
}

const numbers = {
    Equal(n: number | undefined, o: number): boolean {
        return n !== undefined && n === o;
    },

    GreaterThan(n: number | undefined, o: number): boolean {
        return n !== undefined && n > o;
    },

    GreaterThanEqual(n: number | undefined, o: number): boolean {
        return this.GreaterThan(n, o) || this.Equal(n, o);
    }
} as Numbers;

export default numbers;
import {PaymentChannel} from "../../features/PaymentProvider";
import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import {RootState} from "../store";
import {challenge} from "./Navigation";

interface SignInRequest {
    channel: PaymentChannel,
    password: string
}

export const SignIn = createAsyncThunk<void | undefined, SignInRequest, { state: RootState }>(
    "personalDetails/checkPassword",
    ({ channel, password }) => {
        return channel.checkPassword(password);
    }
);

interface State {
    nonce: number;
    reason?: string;
}

const initialState: State = {
    nonce: 0
}

const slice = createSlice({
    name: "signin",
    initialState: initialState,
    reducers: {
    },
    extraReducers: builder => {
        builder.addCase(challenge, (state, action) => {
            const message = action.payload;
            if (message.type !== "signin-by-password")
                return state;

            state.nonce = state.nonce + 1;
            state.reason = message.reason;
            return state;
        });
    }
});

export const {
} = slice.actions;

export type {
    SignInRequest
};

export default slice.reducer;

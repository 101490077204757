import { useEffect } from 'react';
import { WindowMessage } from "../../../app/types";
import { useLocation } from "react-router-dom";
import SpinnerPage from "../SpinnerPage";

function PageReturn() {
    const { search } = useLocation();

    useEffect(() => {
        const searchParams = new URLSearchParams(search);
        const href = searchParams.get("href");
        if (href) {
            (window.top ?? window).location.href = href;
            return;
        }

        if (window && window.parent) {
            const message: WindowMessage = {
                type: "transaction_update",
                message: "complete"
            };

            window.parent.postMessage(message, "*");
        }
    }, [search]);

    return <SpinnerPage />;
}

export default PageReturn;
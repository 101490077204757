import * as React from 'react';
import {createContext, ReactNode } from 'react';
import {UndefinedToEmpty} from "../../app/strings";

export type ThemeName = 'default' | 'flypto' | 'w88' | 'wallet88';
export type VariantName = 'light' | 'dark' | 'embedded';

export interface CustomerSupport {
    phoneNumber: string
}

export interface Security {
    email: string,
    displayName: string
}

export class Theme {
    operatingName: string;
    customerSupport: CustomerSupport;
    security: Security;
    name: ThemeName;
    variant: VariantName;

    constructor(name: ThemeName, variant: VariantName) {
        this.name = name;
        this.variant = variant;
        switch (name) {
            case 'flypto':
                this.operatingName = "Flypto";
                this.customerSupport = {
                    phoneNumber: "1.587.323.9781"
                };
                this.security = {
                    email: "security@flypto.io",
                    displayName: "Security"
                };
                break;
            case 'wallet88':  // The NPC is sending this theme
            case 'w88':
            case 'default':
                this.operatingName = "Wallet88";
                this.customerSupport = {
                    phoneNumber: "1.587.602.3507"
                };
                this.security = {
                    email: "security@wallet88.com",
                    displayName: "Security"
                };
                break;
            default:
                this.operatingName = "Loading...";
                this.customerSupport = {
                    phoneNumber: "1.587.602.3507"
                };
                this.security = {
                    email: "security@wallet88.com",
                    displayName: "Security"
                };
                break;
        }
    }

    inject(css: any): string {
        return `${UndefinedToEmpty(css[this.name])} ${UndefinedToEmpty(css[this.variant])}`;
    }
}

const ThemeContext = createContext<Theme>(new Theme('flypto', 'light'));
export { ThemeContext }

interface ThemeProviderOptions {
    name?: ThemeName | null | undefined,
    variant?: VariantName | null | undefined,
    children?: ReactNode | undefined
}

function ThemeProvider({name, variant, children}: ThemeProviderOptions) {
    let theme: Theme | undefined;
    if (!theme) {
        theme = new Theme(name ?? 'flypto', variant ?? 'light');
    }
    return (<ThemeContext.Provider value={theme}>{children}</ThemeContext.Provider>);
}

export default ThemeProvider;

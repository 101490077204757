export function IsNullOrEmpty(str: string | undefined) {
    return !str || str.trim().length <= 0;
}

export function EmptyToUndefined(s: string | undefined): string | undefined {
    return s === undefined || s === "" ? undefined : s;
}

export function UndefinedToEmpty(s: string | undefined): string | undefined {
    return s === undefined ? "" : s;
}
import React from 'react';
import Bitcoin from "./images/bitcoin.svg";
import Dollar from "./images/dollar.svg";
import Ethereum from "./images/ethereum.svg";
import Euro from "./images/euro.svg";
import css from './index.module.css';

interface FlyptoSpinnerProps {
    aspect?: string | undefined;
    loading?: boolean | undefined;
}

function FlyptoSpinner(props: FlyptoSpinnerProps) {
    const {aspect, loading} = props;

    let className = css.flyptospinner;
    if (aspect !== undefined) {
        className += ` ${css[aspect]}`;
        if (!loading) {
            className += ` ${css.hidden}`;
        }
    }

    return <div className={className}>
        <div className={css.dollar}>
            <img src={Dollar} alt="" />
        </div>
        <div className={css.bitcoin}>
            <img src={Bitcoin} alt="" />
        </div>
        <div className={css.euro}>
            <img src={Euro} alt="" />
        </div>
        <div className={css.ethereum}>
            <img src={Ethereum} alt="" />
        </div>
    </div>
}

export default FlyptoSpinner;

import {createContext, ReactNode} from "react";

type Feature = "start_over";

interface FeatureProviderOptions {
    features: Feature[],
    children?: ReactNode
}

const FeatureContext = createContext<Feature[]>([] as Feature[]);

function FeatureProvider({ features, children}: FeatureProviderOptions) {
    return (<FeatureContext.Provider value={features}>{children}</FeatureContext.Provider>);
}

export type { Feature, FeatureProviderOptions };

export { FeatureContext, FeatureProvider };

export default FeatureProvider;
import {createAsyncThunk, createSlice, PayloadAction} from "@reduxjs/toolkit";
import {ChallengeMessage, PaymentResponse, Reason} from "../types";
import {PaymentChannel} from "../../features/PaymentProvider";
import {RootState} from "../store";

interface EmailAuthorizationState {
    userId: string,
    userName: string,
    email: string,
    realm: string,
    code: string,
    expiresAt: number,
    loading: boolean,
    errors: Reason[],
    processing: boolean,
    resendDisabled: boolean,
}

export const ResendEmailCode = createAsyncThunk<void, PaymentChannel, { state: RootState }>(
    "emailAuthorization/resendEmailCode",
    (channel, ThunkAPI) => {
        const email = ThunkAPI.getState().emailAuthorization.email;
        const realm = ThunkAPI.getState().emailAuthorization.realm;
        return channel.sendNewEmailCode(email, realm);
    }
);

export const AuthorizeEmailCode = createAsyncThunk<void, PaymentChannel, { state: RootState }>(
    "emailAuthorization/authorizeEmailCode",
    (channel, ThunkAPI) => {
        const userId = ThunkAPI.getState().emailAuthorization.userId;
        const code = ThunkAPI.getState().emailAuthorization.code;
        return channel.authorizeEmailCode(userId, code)
    }
);

const initialState = {
    userId: "",
    userName: "",
    email: "",
    realm: "",
    code: "",
    expiresAt: Date.now() + 300000,
    errors: [] as Reason[],
    processing: false,
    resendDisabled: true,
} as EmailAuthorizationState;

const slice = createSlice({
    name: "EmailAuthorization",
    initialState: initialState,
    reducers: {
        updateCode: (state, action: PayloadAction<string>) => {
            state.code = action.payload;
            return state;
        },
        setProcessing: (state, action: PayloadAction<boolean>) => {
            state.processing = action.payload;
            return state;
        },
        setResendDisabled: (state, action: PayloadAction<boolean>) => {
            state.resendDisabled = action.payload;
            return state;
        }
    },
    extraReducers: builder => {
        builder.addCase(ResendEmailCode.fulfilled, state =>{
            state.expiresAt = Date.now() + 300000;
            state.resendDisabled = true;
            state.errors = [] as Reason[];
            return state;
        });
        builder.addMatcher(a => a.type === "Navigation/challenge", (state, action: PayloadAction<ChallengeMessage>) => {
            const payload = action.payload;
            state.processing = false;

            if (payload.type !== "email-code" && payload.type !== "signin-by-email")
                return state;

            state.userId = payload.user_id ?? "";
            state.userName = payload.user_name ?? "";
            state.email = payload.email ?? "";
            state.realm = payload.realm ?? "";
            state.code = "";
            state.expiresAt = Date.now() + (payload.expires_in_seconds ?? 0) * 1000;
            state.errors = [] as Reason[];
            return state;
        });
        builder.addMatcher(a => a.type === "Navigation/paymentResponse", (state, action: PayloadAction<PaymentResponse>) => {
            state.errors = [] as Reason[];
            state.processing = false;

            const code = action.payload.code;
            const reason = action.payload.reason;
            if (code) {
                switch (code) {
                    case "EmailOtpCodeMismatch": {
                        state.errors.push({ field: "code", message: reason } as Reason);
                        break;
                    }
                    case "EmailOtpVerifyMaximumAttempts": {
                        state.errors.push({ field: "code", message: reason } as Reason);
                        break;
                    }
                }
            }

            return state;
        });
    }
});

export const {
    updateCode,
    setProcessing,
    setResendDisabled
} = slice.actions;

export default slice.reducer;
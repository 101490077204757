import React, { Suspense, lazy } from 'react';
import ReactDOM from 'react-dom';
import { store } from './app/store';
import { Provider } from 'react-redux';
import * as serviceWorker from './serviceWorker';
import PaymentProvider from "./features/PaymentProvider";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import PageReturn from './features/Page/Return';
import ThemeProvider, { ThemeName, VariantName } from "./features/ThemeProvider";
import OverlayLoading from './features/OverlayLoading';
import {Feature, FeatureProvider} from './features/Providers';
import './i18n';

const App = lazy(() => import('./App'));

const element = document.getElementById('w88-pos-root');
const theme = element?.getAttribute("data-theme") as ThemeName | undefined | null;
const variant = element?.getAttribute("data-variant") as VariantName | undefined | null;
const url = element?.getAttribute("data-url");
const token = element?.getAttribute("data-token");
const features = element?.getAttribute("data-features")?.split(",").map(s => s.trim() as Feature) ?? [] as Feature[];

let cssOverrides: any = null;
const css = element?.getAttribute("data-css") || "";

try {
    const cssURL = new URL(css);

    if (cssURL.protocol && cssURL.host && cssURL.href) {
        cssOverrides = <link rel="stylesheet" href={ css } />
    } else if (css !== "") {
        cssOverrides = <style>{ css }</style>;
    }
} catch(e) {
    if (css !== "") {
        cssOverrides = <style>{ css }</style>;
    }
}

ReactDOM.render(
    <React.StrictMode>
        <ThemeProvider name={theme} variant={variant}>
            <FeatureProvider features={features}>
                <Provider store={store}>
                    <Router>
                        <Suspense fallback={<OverlayLoading show={true} />}>
                            <Switch>
                                <Route exact path="/app/return" component={PageReturn} />
                                <Route
                                    component={() => (
                                        <PaymentProvider url={url} token={token}>
                                            <App />
                                            {cssOverrides}
                                        </PaymentProvider>
                                    )}
                                />
                            </Switch>
                        </Suspense>
                    </Router>
                </Provider>
            </FeatureProvider>
        </ThemeProvider>
    </React.StrictMode>,
    element
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
import React, { useContext } from 'react';
import {ThemeContext} from "../ThemeProvider";
import FlyptoSpinner from "../FlyptoSpinner";
import {PillSpinner} from "@w88/react-components";
import css from "./index.module.css";

interface ThemedSpinnerOptions {
    aspect?: string | undefined,
    loading?: boolean | undefined
}

export default function ThemedSpinner({ aspect, loading }: ThemedSpinnerOptions) {
    const theme = useContext(ThemeContext);
    switch (theme.name) {
        case 'flypto':
            return <FlyptoSpinner aspect={aspect} loading={loading}/>;

        case 'w88':
        case 'default':
        default:
            const busy = aspect === undefined || !!loading;
            const className = aspect !== undefined ? css[aspect] : '';
            const size = aspect !== undefined ? "small" : 'medium';

            return <PillSpinner busy={busy} className={className} size={size} theme={"dark"}/>
    }
}


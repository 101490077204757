import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {ChallengeMessage, Icon, MethodType} from "../types";

interface InstructionsState {
    method: MethodType | undefined,
    message: string | undefined,
    icon: Icon | undefined
}

const initialState = {

} as InstructionsState;

const slice = createSlice({
    name: "Instructions",
    initialState: initialState,
    reducers: {
    },
    extraReducers: builder => {
        builder.addMatcher(a => a.type === "Navigation/challenge", (state, action: PayloadAction<ChallengeMessage>) => {
            if (action.payload.type === "instructions") {
                state.method = action.payload.method;
                state.icon = action.payload.icon;
                state.message = action.payload.instructions;
            }
            return state
        });
    }
});

/*
 * To be implemented
export const {
} = slice.actions;
*/

export default slice.reducer;

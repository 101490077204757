import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {LimitDescription, MerchantRequest, PaymentResponse} from "../types";

interface ReceiptState {
    method?: string | undefined,
    buyAmount?: string | undefined,
    sellAmount?: string | undefined,
    payee?: string | undefined,
    transactionId?: string | undefined,
    merchant?: string | undefined,
    descriptor?: string | undefined,
    limit?: LimitDescription | undefined,
    returnUrl?: string | undefined,
    exchangeRateAmount?: string | undefined,
    networkFeePaidAmount?: string | undefined,
    depositFeeAmount?: string | undefined,
    totalAmountPaid?: string | undefined,
    currency?: string | undefined,
    buyCurrency?: string | undefined
}

const initialState = {
} as ReceiptState;

const slice = createSlice({
    name: "Receipt",
    initialState: initialState,
    reducers: {

    },
    extraReducers: {
        'ws/connected': (state, action: PayloadAction<MerchantRequest>) => {
            state.returnUrl = action.payload.returnUrl;
            return state;
        },
        'Navigation/paymentResponse': (state, action: PayloadAction<PaymentResponse>) =>  {
            const message = action.payload;
            if (message.status !== "approved")
                return;

            state.method = message["payment-method"];
            state.buyAmount = message["buy-amount"];
            state.sellAmount = message["sell-amount"];
            state.payee = message.payee;
            state.transactionId = message["transaction-id"];
            state.merchant = message.merchant;
            state.descriptor = message.descriptor;
            state.limit = message.limit;
            state.exchangeRateAmount = message["exchange-rate-amount"];
            state.networkFeePaidAmount = message["network-fee-paid-amount"];
            state.depositFeeAmount = message["deposit-fee-amount"];
            state.totalAmountPaid = message["total-amount-paid"];
            state.currency = message.currency;
            state.buyCurrency = message["buy-currency"];
            return state;
        }
    }
});

export default slice.reducer;
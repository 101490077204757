interface IObjects {
    IsUndefined: (obj: any) => obj is undefined,
    IsNull: (obj: any) => obj is null
    Coalesce: (d: NonNullable<any>, ...arr: [any]) => any
}

export const Objects = {
    IsUndefined(obj: any): boolean {
        return obj === undefined;
    },
    IsNull(obj: any): boolean {
        return obj === null
    },
    Coalesce<T>(d: NonNullable<T>, ...arr: [T]): NonNullable<T> {
        for(const a of arr) {
            if (!this.IsUndefined(a) && !this.IsNull(a)) {
                return a as NonNullable<T>;
            }
        }
        return d;
    }
} as IObjects;

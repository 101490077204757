import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {ChallengeMessage} from "../types";

interface PayIdState {
    created: boolean | undefined
    address: string | undefined,
    currency: string | undefined,
    amount: string | undefined
}

const initialState = {
} as PayIdState;

const slice = createSlice({
    name: "PayId",
    initialState: initialState,
    reducers: {
    },
    extraReducers: builder => {
        builder.addMatcher(a => a.type === "Navigation/challenge", (state, action: PayloadAction<ChallengeMessage>) => {
            if (action.payload.type === "payid") {
                state.created = action.payload.created;
                state.address = action.payload.address;
                state.currency = action.payload.currencySymbol;
                state.amount = action.payload.amount;
            }
            return state
        });
    }
});

export default slice.reducer;
import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {Application, CryptoCurrency, CurrencyAmount, QuoteMessage} from "../types";

interface QuoteState {
    application?: Application | undefined,
    merchant?: string | undefined,
    amount?: CurrencyAmount | undefined,
    buyAmount?: string | undefined,
    sellAmount?: string | undefined,
    payee?: string | undefined,
    asset?: CryptoCurrency | undefined
}

const initialState = {

} as QuoteState;

const slice = createSlice({
    name: "Quote",
    initialState: initialState,
    reducers: {
        quote: (state, action: PayloadAction<QuoteMessage>) => {
            const payload = action.payload;
            state.application = payload.application;
            state.amount = payload.amount;
            state.merchant = payload.merchant;
            state.buyAmount = `${payload.buy?.major_unit_quantity} ${payload.buy?.currency}`;
            state.sellAmount = `${payload.sell?.major_unit_quantity} ${payload.sell?.currency}`;
            state.payee = payload.payee;
            state.asset = payload.buy.currency as CryptoCurrency;
            return state
        }
    }
});

export const {
    quote,
} = slice.actions;

export default slice.reducer;

import { useContext } from 'react';
import {ThemeContext} from "../ThemeProvider";
import css from './index.module.css';
import ThemedSpinner from '../ThemedSpinner';

interface OverlayLoadingProps {
    show?: boolean | undefined;
};


function OverlayLoading(props: OverlayLoadingProps) {
    const { show } = props;
    const theme = useContext(ThemeContext);

    return <>
        <div className={`${css.loading_overlay} ${show ? css.visible : ''} ${theme.inject(css)}`}>
            <ThemedSpinner />
        </div>
    </>;
}

export default OverlayLoading;

import { configureStore, ThunkAction, Action } from '@reduxjs/toolkit';
import NavigationReducer from './slices/Navigation';
import UserReducer from './slices/User';
import PaymentReducer from './slices/Payment';
import PersonalDetailsReducer from './slices/PersonalDetails';
import QuoteReducer from './slices/Quote';
import SmsAuthorizationReducer from './slices/SmsAuthorization';
import EmailAuthorizationReducer from './slices/EmailAuthorization';
import InstructionsReducer from './slices/Instructions';
import ReceiptReducer from './slices/Receipt';
import DeclineReducer from './slices/Decline';
import PayIdReducer from './slices/PayId';
import ErrorReducer from './slices/Error';
import SignInReducer from './slices/Signin';

export const store = configureStore({
    reducer: {
        navigation: NavigationReducer,
        user: UserReducer,
        signin: SignInReducer,
        payment: PaymentReducer,
        personalDetails: PersonalDetailsReducer,
        quote: QuoteReducer,
        smsAuthorization: SmsAuthorizationReducer,
        emailAuthorization: EmailAuthorizationReducer,
        instructions: InstructionsReducer,
        receipt: ReceiptReducer,
        decline: DeclineReducer,
        payid: PayIdReducer,
        error: ErrorReducer
    }
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
    ReturnType,
    RootState,
    unknown,
    Action<string>
>;

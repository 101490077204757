import {createAsyncThunk, createSlice, PayloadAction} from "@reduxjs/toolkit";
import {ChallengeMessage, PaymentResponse, Reason} from "../types";
import {PaymentChannel} from "../../features/PaymentProvider";
import {RootState} from "../store";

interface SmsAuthorizationState {
    userId: string,
    code: string,
    number: string,
    expiresAt: number,
    features: string[],
    loading: boolean,
    errors: Reason[],
    processing: boolean,
    resendDisabled: boolean,
}

export const ResendCode = createAsyncThunk<void, PaymentChannel, { state: RootState }>(
    "smsAuthorization/resendCode",
    (channel, ThunkAPI) => {
        const userId = ThunkAPI.getState().smsAuthorization.userId;
        return channel.sendNewCode(userId);
    }
);

export const Authorize = createAsyncThunk<void, PaymentChannel, { state: RootState }>(
    "smsAuthorization/authorize",
    (channel, ThunkAPI) => {
        const auth =  ThunkAPI.getState().smsAuthorization;
        const userId = auth.userId;
        const code = auth.code;
        return channel.authorize(userId, code)
    }
);

const initialState = {
    userId: "",
    code: "",
    number: "",
    expiresAt: Date.now() + 300000,
    errors: [] as Reason[],
    processing: false,
    resendDisabled: true,
    features: [] as string[]
} as SmsAuthorizationState;

const slice = createSlice({
    name: "SmsAuthorization",
    initialState: initialState,
    reducers: {
        updateCode: (state, action: PayloadAction<string>) => {
            state.code = action.payload;
            return state;
        },
        setProcessing: (state, action: PayloadAction<boolean>) => {
            state.processing = action.payload;
            return state;
        },
        setResendDisabled: (state, action: PayloadAction<boolean>) => {
            state.resendDisabled = action.payload;
            return state;
        }
    },
    extraReducers: builder => {
        builder.addCase(ResendCode.fulfilled, state =>{
            state.expiresAt = Date.now() + 300000;
            state.resendDisabled = true;
            state.errors = [] as Reason[];
            return state;
        });
        builder.addMatcher(a => a.type === "Navigation/challenge", (state, action: PayloadAction<ChallengeMessage>) => {
            const payload = action.payload;
            state.processing = false;

            if (payload.type !== "sms-code")
                return state;

            state.userId = payload.user_id ?? "";
            state.number = payload.phone_number ?? "";
            state.code = "";
            state.expiresAt = Date.now() + (payload.expires_in_seconds ?? 0) * 1000;
            state.features = payload.features ?? [] as string[];
            state.errors = [] as Reason[];
            return state;
        });
        builder.addMatcher(a => a.type === "Navigation/paymentResponse", (state, action: PayloadAction<PaymentResponse>) => {
            state.errors = [] as Reason[];
            state.processing = false;

            const code = action.payload.code;
            const reason = action.payload.reason;
            if (code) {
                switch (code) {
                    case "SmsOtpCodeMismatch": {
                        state.errors.push({ field: "code", message: reason } as Reason);
                        break;
                    }
                    case "SmsOtpVerifyMaximumAttempts": {
                        state.errors.push({ field: "code", message: reason } as Reason);
                        break;
                    }
                }
            }

            return state;
        });
    }
});

export const {
    updateCode,
    setProcessing,
    setResendDisabled
} = slice.actions;

export default slice.reducer;
import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {PaymentResponse, ReasonCode} from "../types";

type DeclineType = "limits" | "deposit-declined" | "other";

interface ReceiptState {
    type?: DeclineType | undefined,
    reason?: string | undefined,
    loading?: boolean | undefined
}

const initialState = {
    loading: false
} as ReceiptState;

const slice = createSlice({
    name: "Decline",
    initialState: initialState,
    reducers: {
        startLoading: (state) => {
            state.loading = true;
            return state;
        },
        stopLoading: (state) => {
            state.loading = false;
            return state;
        }
    },
    extraReducers: {
        'Navigation/paymentResponse': (state, action: PayloadAction<PaymentResponse>) => {
            const message = action.payload;
            if (message.status !== "declined" && message.status !== "rejected")
                return;

            state.type = ClassifyErrorCode(message.code);
            state.reason = message.reason;
            return state;
        }
    }
});

function ClassifyErrorCode(code: ReasonCode | undefined): DeclineType {
    switch (code) {
        case "LimitAmount":
            return "limits";

        case "Declined":
            return "deposit-declined";

        default:
            return "other";
    }
}

export const {
    startLoading,
    stopLoading
} = slice.actions;

export default slice.reducer;
